
.campaign__wrapper {
    padding-left: 10px;
    padding-right: 10px;
}

.flex-full-size {
    display: flex;

    & > * {
        flex: 1 1 100%;
        width: 100%;
    }
}

// Header section
#campaign-header {
    .campaign-ended {
        background: $black;
        color: $white;
        padding: 20px;
        margin: 0px -15px 0px;
    }


    #statusbar { padding: 30px; }
}

.sticky {
    position: fixed;
    top: 0;
}

// Menu section
#campaign-menu {
    z-index: 999;

    .sub-nav {
        margin: 0;
        padding-left: 0;

        li {
            margin: 25px 20px;

            @media screen and (max-width: 710px) {
                width: 40%;
            }

            @media screen and (max-width: 510px) {
                margin-left: 5%;
                margin-right: 5%;
                width: 90%;
            }

            a {
                padding-bottom: 4px;
                font-weight: bold;
                text-transform: uppercase;
            }
        }

        a.active {
            border-bottom: 3px solid $primary;
        }

        .btn {
            margin-left: 110px;
            padding: 6px 12px;
        }
    }
}

.campaign__category-list {
  a:not(:last-child)::after {
    content: ',';
    color: $black;
  }
}

// Goals section
#secondary-goals {
    h4 {
        text-align: left;
    }
}

.resource_label{
    border: 1px solid $dark-gray;
    border-radius: 20px;
    color: $dark-gray;
    display: flex;
    flex-wrap: nowrap;
    margin: 0.5em 0;
    overflow: hidden;
    padding: 1rem;
    width: 100%;

    .resource_label__item{
        padding: 0 5px;
    }

    .resource_label__item.row-flex__grow{
        flex: 1 1 auto;
    }

    .resource_label__item.row-flex__dontgrow{
        flex: 0 0 auto;
    }

    .left,
    .right {
        flex: 1 1 50%;
        padding: 0.75em 2em;
        width: 50%;
    }

    .left{
        font-size: $font-size-base;
        line-height: $font-size-base;
    }

    .right {
        font-size: $font-size-small;
        line-height: $font-size-base;
        text-align: right;
    }
}
.resource_label.resource_label--pledged{
    border-color: $brand-primary;
    color: $brand-primary;

    &.media {
      margin-top: 0.5em;
    }
}


// CTA section
.highlight{
    background: #EEE;
    margin: 80px 0;
    padding: 120px 40px;
    text-align: center;
}


// Meta section
#meta{
    margin: 20px auto;
}
#meta H2{
    text-align: center;
}

// Pledge section
.pledge{
    font-size: 16px;
    padding: 20px;
}
.pledge:nth-child(odd) {
   background-color: $lighter-gray;
}
// Small variant
.pledge--small{
    font-size: 12px;
    padding: 10px;

    &:nth-child(odd) {
       background: none;
    }

    .pledge__header IMG{
        max-width: 40px;
    }
}
.pledge__header{
    display: inline-block;
    float: left;
    text-align: right;
    padding-right: 20px;
    position: relative;;
    width: 30%;
}
.pledge__header IMG{
    max-width: 80px;
    height: auto;
    width: 100%;
}
.pledge__header__group-icon{
    border-radius: 5px;
    height: 40px !important;
    overflow: hidden;
    width: 40px !important;
}
.pledge__header__grouppledge-icon{
    border-radius: 5px;
    height: 40px !important;
    margin: 0;
    overflow: hidden;
    width: 40px !important;
}
.pledge__content{
    display: inline-block;
    float: right;
    width: 70%;
}

// Center section
.center{
    margin: 0 auto;
    max-width: 760px;
    padding: 0 20px;

    .buttongroup {
        padding-bottom: 80px;
    }
}
