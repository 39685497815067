#video{
    min-height: 300px;

    .inner {
        overflow: hidden;

        @media screen and (max-width: $screen-sm-max) {
            img { width: 100%; }
        }
    }
}

.videoWrapper {
    height: 0;
    position: relative;
    padding-bottom: 46.25%; /* 16:9 */
    padding-top: 25px;
}
.videoWrapper iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
