// Topbar
.topbar {
  background-color: $brand-primary;
  color: $white;
  height: 60px;
}

.topbar__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
}

.topbar__inner__right {
  margin-left: 1rem;
  flex-shrink: 0;
}

.topbar .dropdown-menu {
  min-width: 320px;
  left: auto;
  right: 0;
  color: $black;
  padding: 0;
}

.topbar__list {
  @extend %list-flex;

  li a {
    color: $white;

    &:hover {
      color: darken($white, 5%);
    }
  }

  li:not(:last-child) {
    margin-right: 2rem;
  }
}

.topbar .btn {
  color: $white;
}

// User menu
.topbar__user-menu {
  img {
    display: inline-block;
    height: 30px;
  }
}

.user-menu {
  display: flex;
  color: $black;
  padding: 1rem;
}

.user-menu__money-limit {
  padding: 1rem 1rem 0;
}

.user-menu__section {
  flex: 1;
}

.user-menu__list {
  @extend %list-reset;

  li:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  li a {
    color: $black;

    &:hover {
      color: $brand-primary;
    }

    &.active {
      color: $gray;
    }
  }
}

.user-menu__list__divider {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $gray;
}
