.form-group{
    margin-bottom: 20px;
}

.form-control{
    color: $black;
    border: none;
    border: 1px solid darken($gray, 10%);
    box-shadow: none;
}

.form-signin{
    margin-bottom: 30px;

    BUTTON{
        margin: 30px 0;
    }
}

.checkbox input {
    position: inherit;
    margin: 10px 10px 0 -20px;
}

.error{
    border: 1px solid #CCC;
    padding: 20px;
    margin: 20px 0;
}

.red{
    border-color: $warning;
    color: $warning;
}

input[type="radio"], input[type="checkbox"] {
    margin-top: 9px;
}

.btn-file {
    position: relative;
    overflow: hidden;
    padding-left: 1em;
    padding-right: 1em;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: inherit;
    display: block;
}
