.navbar{
  margin-bottom: 0;
}
.client-header-logo {
  background: $lighter-gray;

  IMG {
    margin-bottom: 20px;
    margin-top: 20px;
    height: 2.5rem;
    width: auto;
  }
}

.navbar-default {
  //@include gradient-horizontal(darken($primary, 20%), darken($secondary, 20%), 0%, 100%);
  border: none !important;
  border-radius: 0;
  // box-shadow: 0 0 20px 0px RGBA(0,0,0,0.1);
  background-color: $white;
  color: $primary;
  line-height: 1.5;

  .navbar-toggle{
    border-color: $primary;

    .icon-bar{
      background: $primary;
    }

    &:hover{
      background: darken($primary, 25%);
    }
  }

  .navbar__left {
    display: flex;
    flex-wrap: wrap;
  }

  .navbar__brand {
    margin-right: 1rem;
  }

  .navbar-brand {
    color: $primary;
    float: none;
    font-weight: bold;
    height: auto;
    line-height: 20px;
    padding: 0 15px;
    text-transform: uppercase;

    &:hover {
      color: darken($primary, 25%)
    }
  }

  .tagline {
    color: $black;
    font-weight: 300;
    font-size: $font-size-small;
    text-transform: initial;
  }

  .navbar-nav > li > a {
    color: darken($lighter-gray, 50%);

    &.active{
      color: darken($lighter-gray, 30%);
    }
    &:hover {
      color: $primary;
    }

    @media screen and (min-width: $screen-sm-min) {
      padding: 0.2em .5em;
      font-size: $font-size-small;
      margin-left: calc(15px - 0.5em);

      &.active{
        border-color: darken($lighter-gray, 30%);
      }
      &:hover {
        border-color: $primary !important;
      }

      &.cta {
        background: $tertiary;
        border-radius: 30px;
        color: white;
        padding-left: 2rem;
        padding-right: 2rem;
        
        &:hover {
          background: darken($tertiary, 5%);
        }
      }
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    .navbar-nav > li:last-child > a {
      padding-right: 0;
    }
  }

  .navbar-right {
    margin-bottom: 12px;
    margin-top: 12px;
  }
}
