.pcard {
    display: block;
    margin-bottom: 40px;
    min-width: 100%;

    @include transition(opacity 850ms ease, transform 350ms ease);

    > *:first-child{
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        overflow: hidden;
    }
    > *:last-child{
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom: 2px solid darken($gray, 10%);
        overflow: hidden;
    }

    @media screen and (min-width: $screen-sm-min) {
        display: flex;
        min-width: 0;
    }
}

.pcard__bar {
    border-bottom: 1px solid $light-gray;
}

.pcard__image{
    background-color: white;
    background-position: center;
    background-size: cover;
    display: block;
    min-height: 250px;
    position: relative;
}
.pcard__image:after{
    content: "";
    background: $white;
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 2;
    @include transition(opacity 350ms ease);
}
.pcard__image:hover:after{
    opacity: 0.3;
}

.pcard__main {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    background: $white;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 18px;
}

.pcard__goal{
    background: $lighter-gray;
    border-top: 1px solid $light-gray;
    font-size: 12px;
    flex: none;
    line-height: 1.4;
    margin: 0 -15px;
    padding: 10px 15px;
}

.pcard__footer{
    background: $lighter-gray;
    border-top: 1px solid $light-gray;
    flex: none;
    display: flex;
    line-height: 1.4;
    margin: 0 -15px;
    text-transform: uppercase;

    .pcard__footer__left{
        flex: 1 1 auto;
        padding: 10px 5px 10px 15px;
    }
    .pcard__footer__right{
        flex: 1 1 auto;
        justify-content: flex-end;
        margin-top: auto;
        text-align: right;

        A{
            padding: 10px;
            display: inline-block;
            border-left: 1px solid $light-gray;

            &:hover {
                color: $black;
            }
        }
    }

    i{
        color: inherit;
    }
}

.pcard__main__title {
    flex: 1 1 auto;
    margin-bottom: 0;
    min-height: 60px;

    h4 {
        line-height: 1.2;
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        text-transform: uppercase;
    }

    a:hover { text-decoration: none; }
}

.pcard--mini {
    .pcard__footer {
        font-size: 9px;
    }

    .pcard__main__title h4 {
        font-size: 16px;
    }
}