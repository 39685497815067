#footer {
    @include gradient-horizontal($primary, $secondary, 0%, 100%);
    color: $white;
    padding: 20px 0;

    .container-fluid {

        a {
            color: $white;

            &:hover {
                color: $black;
            }
        }

        .info, .sponsors, .contact, .sub-nav {
            padding: 14px;
        }

        .sponsors {
            letter-spacing: 30px;
        }

        .contact {
            float: left;
        }

        .sub-nav {
            float: right;

            li {
                margin-bottom: 10px;
            }

            .active {
                color: $black;
            }
        }

        .footer_logo {
            max-width: 240px;
        }

        .footer__info {
            padding-top: 14px;
        }
    }

    @media screen and (max-width: $screen-xs-max) {
        .container-fluid {
            .info, .contact, .sub-nav {
                float: none;
                text-align: center;
            }

            .sub-nav {
                border-top: 1px solid $light-gray;

                li {
                    display: block;
                    margin-left: 0;
                }
            }

            .footer__info {
                text-align: center;
            }
        }
    }
}
