#home {
    padding-bottom: 60px;

    .front-msg {
        text-align: center;
    }

    .campaign__image{
        position: relative;
    }
    .campaign__image:after{
        content: "";
        background: $white;
        opacity: 0;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: 2;
        @include transition(opacity 350ms ease);
    }
    .campaign__image:hover:after{
        opacity: 0.3;
    }
}
