.searchbox{
  margin: 2em 0;
  @extend .cf;
}

.stylish-input-group .input-group-addon{
    background: $white !important;
}

.stylish-input-group .form-control{
  border-right:0;
  box-shadow:0 0 0;
  border-color:#ccc;
}
.stylish-input-group button{
    border: 0;
    background:transparent;
    color: $black;
}