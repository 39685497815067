// m-contexthelp
.m-contexthelp{
  display: inline-block;
  position: relative;

  &.is-active{
    padding: 1em;
    background: $white;
    margin: 1em 0;
  }
}

.m-contexthelp__content{
  display: none;
}

.m-contexthelp__toggle{
  color: $primary;
  font-size: 2em;
  line-height: 1;
  transition: .15s;
}
.is-active .m-contexthelp__toggle{
  color: $warning;
  position: absolute;
  top: -0.25em;
  right: -0.25em;
}

.m-contexthelp.is-active{
   .m-contexthelp__content{
    display: block;
  }
}