#login, #createProfile {

    #inputPassword {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
     }

    #inputEmail {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    // Social login buttons
    .btn--facebook {
        background: $facebook-blue;

        &:hover {
            background: darken($facebook-blue, 5%);
        }
    }
    .btn--linkedin {
        background: $linkedin-blue;

        &:hover {
            background: darken($linkedin-blue, 5%);
        }
    }
}
