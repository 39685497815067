.m-media {
  display: flex;
  align-items: flex-start;
}

.m-media-figure {
  margin-right: 0.5em;
}

.m-media-body {
  flex: 1;
  word-break: break-word;
}