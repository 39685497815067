#statusbar{
    flex: 0 1 auto;
    line-height: 1.2;

    h4 {
        font-size: 18px;
        line-height: 1.2;
    }

    .bar-ended {
        background: $white;
        color: $black;
    }

    .bar,
    .bar__percentage,
    .bar__info{
        height: 27px;
    }
    .bar__percentage,
    .bar__info{
        SPAN{
            font-size: 11px;
            padding-bottom: 8px;
            padding-top: 8px;
        }
    }

    .days-left h4 {
        text-align: center;
    }
}

.bar,
.bar__percentage,
.bar__info{
    height: 43px;
}

.bar{
    background: $lighter-gray;
    display: block;
    font-size: 0;
    line-height: 0;
    position: relative;
}

.bar__percentage,
.bar__info{
    display: inline-block;
    letter-spacing: 2px;
    overflow: hidden;
    text-transform: uppercase;
    white-space: nowrap;

    SPAN{
        display: inline-block;
        font-size: 13px;
        line-height: 1;
        padding: 15px 20px;
    }
}

.bar__percentage{
    color: #FFF;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2;
    width: 0%;

    @include gradient-horizontal($primary, $secondary, 0%, 100%);
}

/* Small variant */
.bar--small{
    height: 23px;

    .bar__percentage,
    .bar__info{
        height: 23px;
    }

    .bar__percentage,
    .bar__info{
        SPAN{
            padding: 5px 10px;
        }
    }
}


.resources_percentage {
    background: $tertiary;
}


.not-funded-sticker,
.funded-sticker{
    color: #FFF;
    background: $primary;
    flex: 0 1 auto;
    font-size: $font-size-small;
    font-weight: 600;
    padding: 0.5em;
    text-align: center;
}
.not-funded-sticker{
    background: $black;
}

.agree{
    background: $primary;
    color: #FFF;
    display: block;
    font-size: 22px;
    line-height: 1;
    margin: 0;
    padding: 8px 0;
    text-align: center;
}
.doesntagree{
    background: $warning;
    color: #FFF;
    display: block;
    font-size: 22px;
    line-height: 1;
    margin: 0;
    padding: 8px 0;
    text-align: center;
}
