// Options

$chosen-background: $input-bg !default;
$chosen-border: 1px solid $input-border !default;
$chosen-border-color: $input-border-focus;
$chosen-border-radius: 0;
$chosen-multi-border-radius: 0;
$chosen-box-shadow: none;
$chosen-drop-border: $input-border !default;
$chosen-drop-box-shadow: none;
$chosen-drop-zindex: 1060 !default;
$chosen-focus-border: 1px solid $input-border-focus !default;
$chosen-focus-box-shadow: none;
$chosen-focus-transition: border linear .2s;
$chosen-height: $input-height-base !default;
$chosen-multi-height: $input-height-base + 6px !default;

$chosen-sprite-path: '/img/chosen-sprite.png';
$chosen-sprite-retina-path: '/img/chosen-sprite@2x.png';

// Import

@import "./vendor/bower_components/bootstrap-chosen/bootstrap-chosen.scss";

.chosen-container-single .chosen-single,
.chosen-container .chosen-results { color: $gray-dark; }