.usermenu__notifications-count {
    position: absolute;
    font-size: 12px;
    right: 12px;
    padding: 0px 7px;
    background: $tertiary;
    border-radius: 50%;
}

.notifications {
    font-size: 14px;
    color: $black;
    width: 320px;
    padding: 0;

    // Header
    .notifications__header {
        padding: 12px 8px;
        border-bottom: $border-gray;
    }

    .notifications__header-links {
        text-align: center;

        p { margin-bottom: 0; }

        a {
            color: $link-color;

            &:hover { color: darken($link-color, 15%); }
        }
    }

    // Items
    .notifications__item-list {
        max-height: 360px;
        overflow-y: scroll;
    }

    .notifications__item a {
        display: flex;
        flex-wrap: nowrap;

        color: $black;
        padding: 8px;
        border-bottom: $border-gray;

        &:hover { background-color: $lighter-gray; }

        &.unread {
            background: rgba($primary, 0.2);

            &:hover { background-color: $lighter-gray; }
        }
    }

    .notifications__item-image {
        img {
            height: 50px;
            margin: 0;
        }
    }

    .notifications__item-content {
        flex-grow: 1;
        padding: 0 8px;
        line-height: 18px;

        p {
            width: 210px;
            margin-bottom: 0;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
        }

        span {
            font-size: 12px;
            color: $medium-dark-gray;
        }
    }

    .notifications__item-actions {
        button {
            color: $medium-dark-gray;
            background-color: transparent;
            border: none;

            &:hover { color: $dark-gray; }

            &:focus { outline: none; }
        }

        button i { font-size: 12px; }
    }
}
