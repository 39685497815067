.responsive-table {
  @extend .table;

  thead tr th{
    font-weight: bold;
    border-bottom: 2px solid $primary;
  }

  tr{
    td {
      vertical-align: middle;
    }

    &:nth-child(even) {
      background: $light-gray;
    }
  }

  .responsive-table {
    background: transparent;
    margin-top: 3rem;

    tr, th { background: transparent }

    tr{
      &:nth-child(even) {
        background: $light-gray;
      }

      &:nth-child(odd) {
        background: $white;
      }
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    thead { display: none; }

    th{
      background: $primary;
      color: $white;
      font-weight: normal;
    }

    tbody tr {
      padding: 2em 0;
    }

    tbody tr td {
      display: flex;
      padding: 4px 8px;
      border-top: none;

      // Using the data-th attribute as inline table row headers on mobile
      &:before {
        flex: 1;
        content: attr(data-th)"";
        font-weight: 600;
      }

      &:first-child { padding-top: 1.5em; }
      &:last-child { padding-bottom: 1.5em; }
    }

    &--no-labels {
      tbody tr td:before { display: none; }
    }

    .responsive-table__cell-content { flex: 1; }
  }
}
