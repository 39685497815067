$frontpage-block-padding: 3rem;

/*
**  Banner
*/
.banner__container {
  margin: 3rem auto;
  max-width: $container-large-desktop + $grid-gutter-width;
  padding: 0 $grid-gutter-width;
  width: 100%;
}

.banner {
  border-top: 1px solid $lighter-gray;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;

  .banner__inner {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }

  &::before {
    // @include gradient-vertical($primary, $secondary, 0%, 100%);
    background: $lighter-gray;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .banner__content {
    color: $medium-dark-gray;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    position: relative;

    H4{
      color: $dark-gray;
      margin: 0 0 0.5rem;
    }
  }
}

.banner--front {
  color: $white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;

  &::before {
    @include gradient-vertical(rgba(0,0,0,0.55), rgba(0,0,0,0.55), 0%, 100%);
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .banner__inner {
    max-width: 400px;
    margin: 8em 0 2em;
  }

  .banner__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    position: relative;
  }
}

/*
**  Content block
*/
.content-block {
  padding: $frontpage-block-padding 0;
}

/*
**  USP blocks
*/
.usp-blocks {
  @extend .row;
  padding: $frontpage-block-padding 0;
}

.usp__single {
  @extend .col-xs-12, .col-sm-4;
  text-align: center;
  margin: 2rem 0;

  i {
    font-size: 3em;
    margin-bottom: 2rem;
  }

  // Icon color variations
  &.primary i {
    color: $primary;
  }
  &.secondary i {
    color: $secondary;
  }

  .usp__title {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0;
  }
}

/*
**  Feature blocks
*/
.feature-blocks {
  @extend .row;
  padding: $frontpage-block-padding 0;
}

.feature__single {
  @extend .col-xs-12, .col-sm-4;
  margin: 2rem 0;

  .feature__header {
    font-weight: 600;
    margin-bottom: 1rem;
    display: flex;
  }

  .feature__step {
    flex-shrink: 0;
    margin-right: 1.5rem;
  }

  .step-counter {
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    border: 2px solid $black;
  }

  .feature__title {
    margin-top: 7px;
  }

  p {
    margin-bottom: 0;
  }
}


/*
**  Newest content
*/
.newest-campaigns, .newest-resources {
  padding-top: $frontpage-block-padding;

  .heading--flex {
    margin-bottom: 2rem;
  }
}
