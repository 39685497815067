// Frontpage tour
.tour-frontpage {
    #step-1 {
        margin-top: 30px;
        margin-left: 80px;
    }
    #step-2 {
        margin-top: -20px;
    }
    #step-3 {
        margin-top: 35px;
        margin-left: -175px;
    }
}

// Create campaign tour
.tour-createcampaign {
    #step-1, #step-2, #step-3, #step-4 { margin-top: -20px; }
}

// Campaign page tour
.tour-campaignpage {
    #step-1 { margin-top: 20px; }
    #step-2, #step-3, #step-4, #step-5 { margin-top: -20px; }
}
