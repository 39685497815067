.modal-header {
    background-color: $primary;
    color: $white;
    border-bottom: none;
    border-radius: 6px 6px 0 0;

    .close {
        font-size: 20px;
        float: right;
        border: none;
        background: none;
        padding: 0;

        &:hover { color: $light-gray; }
    }
}

.modal-body {
  line-height: 1.65;
}

.modal-body, .modal-footer {
    color: $black;
    border-bottom: none;
}

.modal-input { margin-top: 10px; }

#feedbackModal {
    .alert {
        display: none;
    }

    .errors {
        background: #d6425f;
        color: $white;
        border-radius: 3px;
        clear: both;
        padding: 10px;
        margin-bottom: 0;
    }
}
