#campaign-creation {
    .money, .resources, .grouppledge {
        background: $lighter-gray;
        padding: 16px;
        margin-bottom: 10px;

        .checkbox {

            label {
                margin-bottom: 0;
            }
        }
    }

    .resources-input {
        input, a {
            margin-bottom: 20px;
        }
    }

    .hide {
        display: none;
    }
}
