.btn {
    border: none;
    padding-top: 10px;
    font-size: $font-size-small;
    padding-bottom: 10px;

    &:active:focus {
      outline: 0;
    }

    @include transition(all 150ms ease);
}

.btn-round{
    border-radius: 2em;
}

.btn-circle-icon{
    border-radius: 50%;
    display: inline-block;
    font-size: 1.5rem;
    height: 4.5rem;
    line-height: 1;
    padding: 1.5rem;
    text-align: center;
    width: 4.5rem;
}

.btn-lg{
    font-size: $font-size-base;
    padding-top: 1em;
    padding-bottom: 1em;
    text-transform: uppercase;
}

.btn-xs{
    padding-left: 1.5rem;
    font-size: $font-size-small;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-right: 1.5rem;
}

.btn-mini{
    font-size: $font-size-small;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.btn-ghost{
    background: transparent;
    border: 2px solid $primary;
    color: $primary;

    i{
        @include transition(all 150ms ease);
    }
}

.btn-ghost:hover{
    background: transparent;
    border-color: $tertiary;
    color: $tertiary;

    i{
        color: $tertiary;
    }
}

.btn-ghost--white{
    background: transparent;
    border: 2px solid $white;
    color: $white;

    i{
        color: $white;
        @include transition(all 150ms ease);
    }
}

.btn-ghost--white:hover{
    background: transparent;
    border-color: $light-gray;
    color: $light-gray;

    i{
        color: $light-gray;
    }
}

.btn-gray{
    background: $gray;
    color: white;
}

.btn-gray:hover{
    background: $dark-gray;
    color: white;
}

.btn-white{
    background: $white;
    color: $dark-gray;
}

.btn-white:hover{
    background: $dark-gray;
    color: white;
}

.btn-link {
    padding: 0;

    &:hover, &:focus {
      text-decoration: none;
    }
}

.btn .caret {
  margin-left: 5px;
}

.btn-like {
  color: $text-color;

  i {
    margin-right: 3px;
  }

  &:hover,
  &.active {
    color: $primary;
  }
}
