.dropdown-menu {
  .dropdown-divider {
    height: 1px;
    margin: .5rem 0;
    overflow: hidden;
    background-color: $light-gray;
  }

  .btn-link {
    width: 100%;
    padding: 3px 20px;
    color: $black;

    &:hover {
      color: $dropdown-link-hover-color;
      background-color: $dropdown-link-hover-bg;
    }
  }

  > li > a > i {
    min-width: 25px;
    text-align: center;
  }
}

.category-dropdown {
  HR {
    margin: 5px -10px;
  }
}

.category-dropdown__limiter{
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 250px;
}

.category-dropdown__btn{
    display: block;
    width: 100%;
}