.popover {
  padding: 0;
}

.popover-title {
  font-size: 18px;
  color: $white;
  padding: 14px;
}

.popover.bottom > .arrow:after {
  border-bottom-color: $primary;
}

.popover-content {
  font-size: 15px;
  padding: 14px;
}

.popover-content .videoWrapper {
    margin-top: 8px;
}

.popover .popover-navigation, .popover[class*=tour-] .popover-navigation {
  padding: 4px 14px;
  border-top: $border-gray;
}

.popover-navigation .btn {
  @extend .btn-link;

  padding: 10px;

  &:focus {
    outline: none;
  }
}

.popover-navigation .btn-right {
    float: right;
}
