// Containers
.container--fullscreen {
    margin-top: -20px;

    @media screen and (min-width: $screen-md-min) {
        margin-left: -20px;
        margin-right: -20px;
    }
}

.container--small {
    margin: 0 auto;
    max-width: 960px;
}