.imagefix{
    font-size: 0;
    line-height: 0;
}

.bg--projectpad {
    background: url(/img/bg_pp.jpg) no-repeat center center;
    background-size: cover;
}

.bg-white {
    background: $white;
}

.bg-gray {
    background: $lighter-gray;
}

.bg-light-gray {
    background: $light-gray;
}

.img-small-avatar{
    max-width: 60px;
}

.w-full { width: 100%; }

.mb-0{ margin-bottom: 0px; }
.mb-10{ margin-bottom: 10px; }
.mb-20{ margin-bottom: 20px; }
.mb-40{ margin-bottom: 40px; }
.mt-0{ margin-top: 0px; }
.mt-10{ margin-top: 10px; }
.mt-20{ margin-top: 20px; }
.mt-40{ margin-top: 40px; }
.mt-60{ margin-top: 60px; }

.pb-0{ padding-bottom: 0; }
.pb-5{ padding-bottom: 5px; }
.pb-10{ padding-bottom: 10px; }
.pb-20{ padding-bottom: 20px; }
.pb-40{ padding-bottom: 40px; }
.pt-0{ padding-top: 0; }
.pt-5{ padding-top: 5px; }
.pt-10{ padding-top: 10px; }
.pt-20{ padding-top: 20px; }
.pt-40{ padding-top: 40px; }

.p10{ padding: 10px; }
.p20{ padding: 20px; }
.p30{ padding: 30px; }
.p40{ padding: 40px; }

.my-1 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.row-eq-height {
    @extend .row;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.height-full{
    height: 100%;
}

.color-highlight{
    color: $primary;
}

.flex-column{
    display: flex;
    flex-direction: column;

    &> * {
        flex: 1 1 auto;
    }
}

%list-reset,
.list-reset {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

%list-flex,
.list-flex {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.cf:before,
.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after {
    clear: both;
}

/**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.cf {
    *zoom: 1;
}

.break-word{
    word-break: break-word;
}

.nowrap {
    white-space: nowrap;
}

.border-primary {
    border-color: $primary;
}