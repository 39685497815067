[data-toggle="tooltip"] {
    z-index: 3;

    &.fa {
        font-size: 18px;
        color: $primary;
    }

    &.input-help {
        position: absolute;
        top: 10px;
        right: 25px;
    }
}
