.alert ul,
.alert li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.alert li {
  margin-bottom: 0.25em;
}


.alertsection {
  background: $white;
  color: $primary;
  border: 1px solid $primary;
  border-radius: 3px;
  font-size: $font-size-small;
  padding: 1rem 2rem;
  text-align: center;

  &--warning {
    color: $warning;
    border-color: $warning;
    text-align: left;
  }
}