// Borders
$border-primary: 1px solid $primary;
$border-gray: 1px solid $light-gray;

// Headings
.heading--flex {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > a:last-child,
  > button:last-child {
    flex-shrink: 0;
    margin-left: 10px;
  }
}

// Descriptions
.description {
    background: #FEFEFE;
    border: 1px solid #EEE;
    padding: 20px;
    font-size: $font-size-small;
    line-height: 1.2;

    P{
        margin: 0 0 5px;;
        padding: 0;
    }

    PRE{
        background: none;
        border: none;
        font-size: $font-size-small;
        line-height: 1.2;
        margin: 0 0 10px;;
        padding: 0;
    }
}

// Misc
HR{
    border: none;
    border-bottom: 1px solid $gray;
    margin: 20px 0;
    padding: 0;
}

.block{ display: block; }

.bggray{ background: $lighter-gray; }
.bgwhite{ background: $white; }

// Tour
.tour-backdrop {
    background: $black;
    opacity: .4;
}

.row--flex {
    @extend .row;
    display: block;

    @media screen and (min-width: $screen-sm-max) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}