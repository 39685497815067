// Money limit area
.money_limit{
    background: darken($brand-primary, 5%);
    color: white;
    font-size: 15px;
    line-height: 1;
    padding-bottom: 0.8em;
    padding-top: 0.8em;
}
// User menu
.usermenu {
    background: $brand-primary;
    color: white;
    font-size: 15px;
    line-height: 1;

    A{
        color: white;
    }

    IMG {
        display: inline-block;
        height: 30px;
        margin: 10px 0;
    }
    .usermenu__info {
        margin: 10px 0;
        padding-top: 4px;
    }


    .usermenu__info, .usermenu__admin {
        .dropdown-menu > li > a {
            text-align: left;
        }
    }

    .usermenu__money {
        font-size: 14px;
    }

    .btn-link {
        color: $white;
    }
}

.usermenu__right {
    float: right;

    > div {
      display: inline-block;
      margin: 13px 18px 13px 0;

      &:last-child {
        margin-right: 0;
      }

      button {
        position: relative;
      }
    }

    .fa {
      font-size: 16px;
    }

    .dropdown-menu {
        left: auto;
        right: 0;
    }
}

@media screen and (max-width: $screen-sm-min) {

  // TODO: move caret into position
  .username-limit{
      display: inline-block;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
}

// Sub navigation
.sub-nav {
    list-style-type: none;

    li {
        display: inline-block;
        margin-left: 5px;

        a {
            font-size: $font-size-small;

            i{
                display: none;
                margin-right: 0.5em;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
    li:first-child{ margin-left: 0; }

    .counter {
        color: $tertiary;
        margin-left: 5px;
    }
}

