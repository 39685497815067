.overview-filters {
  display: flex;
  flex-wrap: wrap;
}

.overview-filters__left,
.overview-filters__right {
  display: flex;
  margin-bottom: 20px;

  @media screen and (max-width: $screen-xs-max) {
      width: 100%;
  }
}

.overview-filters__right {
  margin-left: auto;

  .dropdown:not(:last-child) {
    margin-right: 10px;
  }

  @media screen and (min-width: $screen-sm-min) {
    .dropdown-menu {
      left: auto;
      right: 0;
    }
  }
}
