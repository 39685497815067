.image-popup {
    align-items: center;
    background: rgba($black, 0.86);
    bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;

    .inner {
        font-size: 0;
        line-height: 0;
        max-height: calc(100% - 20px);
        max-width: calc(100% - 20px);
    }

    img {
        display: block;
        height: auto;
        width: 100%;
    }
}