BODY{ overflow-x: hidden; }

#maincontent{
    @media screen and (min-width: $screen-md-min) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

// Sections
legend{
 font-size: $font-size-base;
}

.text-sm {
  font-size: $font-size-small;
}

.pull-left {
  float: left;
}