.pagination {
    text-align: center;
    margin: 0;
    width: 100%;

    > li { display: inline-block; }

    > li > a,
    > li > span {
        padding: 0 6px;
        margin: 0 2px;
        border-radius: 6px;

        @media screen and (min-width: $screen-sm-min) {
            padding: 2px 12px;
        }
    }

    > li:first-child > a,
    > li:first-child > span {
        color: $primary;
        background: none;
        margin: 0;
        &:hover { color: $pagination-hover-bg; }
    }

    > li:last-child > a,
    > li:last-child > span {
        color: $primary;
        background: none;
        margin: 0;
        &:hover { color: $pagination-hover-bg; }
    }

    > .disabled {
      > span,
      > span:hover,
      > span:focus,
      > a,
      > a:hover,
      > a:focus {
        color: $pagination-disabled-color !important;
        background-color: transparent;
      }
    }
}
