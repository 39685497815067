.campaign__image{
    background-color: white;
    background-position: center;
    background-size: cover;
    display: block;
    min-height: 150px;

    @media screen and (min-width: $screen-xs-min) {
        min-height: 400px;
    }
}
.campaign__image--small{
    @extend .campaign__image;

    min-height: 200px;
}
.campaign__image--teaser{
    min-height: 250px;
}
.pcard--mini .campaign__image--teaser {
    min-height: 125px;
}
.campaign-title {
    margin-bottom: 40px;
}

.campaign__grouppledge_stats{
    flex: 0 1 auto !important;
}

.miniteaser{
    background: $white;
    border-bottom: 2px solid darken($gray, 10%);
    border-radius: 5px;
    overflow: hidden;
    padding-bottom: 1px;
}

.miniteaser__title{
    margin-top: 0;
    font-size: $font-size-large;
    font-weight: 600;
}

.miniteaser__header{
    A{ display: block; }
}

.miniteaser__content{
    padding: 30px 20px 0;
}

.miniteaser__footer{
    margin: 30px 0 0 0;
}
