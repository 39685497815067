$toggleswitch-height: 2em !default;

.m-toggleswitch {
  display: none;

  + .m-toggleswitch__btn {
    outline: 0;
    display: block;
    width: $toggleswitch-height*2;
    height: $toggleswitch-height;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .m-toggleswitch__btn:after {
    left: 50%;
  }
}

// themes
.m-toggleswitch--light {
  + .m-toggleswitch__btn {
    background: $white;
    border: 1px solid $gray;
    border-radius: $toggleswitch-height;
    box-sizing: content-box;
    padding: 2px;
    transition: all .4s ease;
    &:after {
      border-radius: 50%;
      background: $gray;
      transition: all .2s ease;
    }
  }

  &:checked + .m-toggleswitch__btn {
    background: $primary;
    &:after {
      background: $white;
    }
  }
}